export const GET_PENDINGPO = 'GET_PENDINGPO';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const GET_PURCHASEORDERS = 'GET_PURCHASEORDERS';

export const reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_PENDINGPO:
      return {
        ...state,
        poList:
          payload.page === 1
            ? payload.poList
            : state.poList.concat(payload.poList),
        poListLength: payload.poListLength,
      };
    case UPDATE_RECORD:
      return {
        ...state,
        poList: [
          ...state.poList.slice(
            0,
            state.poList.findIndex(
              data => data.id === payload.updatedRequest.id
            )
          ),
          { ...payload.updatedRequest },
          ...state.poList.slice(
            state.poList.findIndex(
              data => data.id === payload.updatedRequest.id
            ) + 1
          ),
        ],
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: payload.customers,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: [
          ...state.customers.slice(
            0,
            state.customers.findIndex(
              data => data.id === payload.newCustomer.id
            )
          ),
          payload.newCustomer,
          ...state.customers.slice(
            state.customers.findIndex(
              data => data.id === payload.newCustomer.id
            ) + 1
          ),
        ],
      };
    case GET_PURCHASEORDERS:
      return {
        ...state,
        poList:
          action.payload.page < 2
            ? action.payload.po
            : state.po.concat(action.payload.po),
        poListLength: payload.poLength,
      };
    default:
      return state;
  }
};
