import React, { useReducer, createContext } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import {
  reducer,
  GET_PENDINGPO,
  UPDATE_RECORD,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
  GET_PURCHASEORDERS,
} from './PendingReducer';
import { headers } from '../config/token';
import { displayErrors, displayNotification } from '../config/display';

const initialState = {
  customers: [],
  poList: [],
  poListLength: 0,
};
export const PContext = createContext({});
const PendingContext = ({ children }) => {
  const API = process.env.REACT_APP_API_URL || 'http://api.exelpack.site/api/';
  const [values, dispatch] = useReducer(reducer, initialState);

  const getFilterParams = filter => {
    let params = '';
    Object.keys(filter).forEach(key => {
      params += filter[key] ? `&${key}=${filter[key]}` : '';
    });
    return params;
  };

  const getpendingApprovalList = (
    setLoading,
    filter,
    page = 1,
    shouldLoadAll = ``
  ) => {
    setLoading(true);
    const filterParams = getFilterParams(filter);
    Axios.get(
      `${API}gdash/po?page=${page}${filterParams}${shouldLoadAll}`,
      headers()
    )
      .then(res => {
        const { poList, poListLength } = res.data;
        dispatch({
          type: GET_PENDINGPO,
          payload: { poList, poListLength, page },
        });
      })
      .catch(err => displayErrors(err))
      .finally(() => setLoading(false));
  };

  const approveOrReject = (type, id, setLoading) => {
    setLoading(true);
    Axios.put(`${API}gdash/po/action/${id}`, { type }, headers())
      .then(res => {
        const { updatedRequest, message } = res.data;
        dispatch({
          type: UPDATE_RECORD,
          payload: {
            updatedRequest,
          },
        });
        displayNotification('success', message);
      })
      .catch(err => displayErrors(err))
      .finally(() => setLoading(false));
  };

  const updateRemarks = (data, setLoading) => {
    setLoading(true);

    const { remarks } = data;
    if (!remarks || remarks === '' || remarks.trim() === '') {
      displayNotification('error', 'Remarks is required');
      setLoading(false);
    } else {
      Axios.put(`${API}gdash/po/${data.id}`, data, headers())
        .then(res => {
          const { updatedRequest, message } = res.data;
          dispatch({
            type: UPDATE_RECORD,
            payload: {
              updatedRequest,
            },
          });
          displayNotification('success', message);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          displayErrors(err);
        });
    }
  };

  const getCustomers = async (setLoading = () => null) => {
    setLoading(true);
    try {
      const res = await Axios.get(`${API}gdash/customer`, headers());
      const { customers } = res.data;
      dispatch({
        type: GET_CUSTOMERS,
        payload: {
          customers,
        },
      });
    } catch (err) {
      displayErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const updateCustomer = async (data, setLoading, closeModal) => {
    setLoading(true);
    try {
      const res = await Axios.post(
        `${API}gdash/customer/${data.id}`,
        data,
        headers()
      );
      const { newCustomer, message } = res.data;
      dispatch({
        type: UPDATE_CUSTOMER,
        payload: {
          newCustomer,
        },
      });
      displayNotification('success', message);
      closeModal();
    } catch (err) {
      displayErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const getPurchaseOrder = async (
    setLoading,
    filter = {},
    page = 1,
    shouldLoadAll = ``
  ) => {
    setLoading(true);
    try {
      const parameters = getFilterParams(filter);
      const res = await Axios.get(
        `${API}cposms/po?page=${page}${parameters}${shouldLoadAll}`,
        headers()
      );
      const { po, poLength } = res.data;
      dispatch({
        type: GET_PURCHASEORDERS,
        payload: {
          po,
          poLength,
          page,
        },
      });
    } catch (err) {
      displayErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const getSpoDetails = async id => {
    try {
      const res = await Axios.get(`${API}gdash/po/${id}`, headers());
      return res.data;
    } catch (err) {
      displayErrors(err);
      throw new Error(err);
    }
  };

  const getPoDetails = async id => {
    try {
      const res = await Axios.get(`${API}overall/po/${id}`, headers());
      return res.data;
    } catch (err) {
      displayErrors(err);
      throw new Error(err);
    }
  };

  return (
    <PContext.Provider
      value={{
        values,
        getpendingApprovalList,
        getSpoDetails,
        approveOrReject,
        updateRemarks,
        getCustomers,
        updateCustomer,
        getPurchaseOrder,
        getPoDetails,
      }}
    >
      {children}
    </PContext.Provider>
  );
};
PendingContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PendingContext;
