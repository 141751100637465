import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';

import PrItemTable from './PrItemTable';
import PoDetails from './PoDetails';
import PrSummary from './PrSummary';

const PrInformationModal = props => {
  const { visible, getSpoDetails, data, closeModal } = props;
  const [spin, setSpin] = useState(true);
  const [details, setDetails] = useState({
    spoItems: [],
    poDetails: {},
    poItems: [],
    poHistory: [],
  });
  useEffect(() => {
    getSpoDetails(data.po_id)
      .then(res => {
        setDetails({ ...res });
        setSpin(false);
      })
      .catch(() => setSpin(false));
  }, []);

  const { spoItems, poDetails, poItems, poHistory } = details;
  return (
    <Modal
      visible={visible}
      title="Request details"
      onCancel={() => closeModal('pr')}
      width={1000}
      footer={null}
      style={{ top: 20 }}
    >
      <Spin spinning={spin}>
        <div style={{ overflowX: 'auto' }}>
          <PrSummary
            withExistingRm={poHistory.some(po =>
              po.joNumber.split(',').includes(data.joNum)
            )}
            prTotal={spoItems.reduce((acc, val) => acc + val.amount, 0)}
            itemTotalBudget={poItems
              .filter(item => item.isMatchItem)
              .reduce((acc, val) => acc + val.budgetPriceTotal, 0)}
          />
          <h4 style={{ fontWeight: 600 }}>PURCHASE ORDER ITEMS</h4>
          <PrItemTable prItems={spoItems} />
          <br />
          <h4 style={{ fontWeight: 600 }}>CUSTOMER PO DETAILS</h4>
          <PoDetails
            poDetails={poDetails}
            poItems={poItems}
            poHistory={poHistory}
          />
        </div>
      </Spin>
    </Modal>
  );
};
PrInformationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
  getSpoDetails: PropTypes.func.isRequired,
};

export default PrInformationModal;
