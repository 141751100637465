import PendingPrView from '../views/PendingPrView';
import CustomersView from '../views/CustomersView';
import PurchaseOrderView from '../views/PurchaseOrderView';

export const brandnameLg = { name: 'TMPLTE', suffix: 'MS' }; // BRANDNAME TO BE DISPLAY WHEN ON LG MODE,MAX OF 10 CHARACTERS FOR NAME AND 5 FOR SUFFIX
export const brandnameSm = 'TMPLTE'; // SHORTER NAME or acronym for BRANDNAME TO BE DISPLAY WHEN ON MOBILE SIZE, MAX OF 8 CHARACTERS ONLY
export const redirectPath = '/home'; // redirect path if successfully logged in
export const API = process.env.REACT_APP_API_URL; // api link

// https://ant.design/components/icon/ for icons
export const routes = [
  // array for routes
  {
    component: PendingPrView,
    title: 'Purchase Oder',
    icon: 'list-ul',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/home',
  },
  {
    component: CustomersView,
    title: 'Customer',
    icon: 'user',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/customer',
  },
  {
    component: PurchaseOrderView,
    title: 'Customer PO',
    icon: 'file-invoice',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/po',
  },
];
